import React from "react";
import { useDropzone } from "react-dropzone";
import { useEffect, useState, useCallback } from "react";
import "../MObjectStart.css";
import papa from "papaparse";
import {
	allDbMappings,
	crmLeadsAllDbMappings,
	crmAccountsAllDbMappings,
	crmContactsAllDbMappings,
	crmOpportunitiesAllDbMappings,
} from "../constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../toast.css";
import Alert from "@mui/material/Alert";
import { FormControl, Select, InputLabel, MenuItem } from "@mui/material";

function DropZone(props) {
	// const getFilesFromEvent = (event) => {
	//   console.log("event coming from basic.js", event)
	//   props.setSelectedFileData({ selectedFile: event?.target?.files[0] });
	//   props.setSelectFileName(event?.target?.files[0]?.name);
	// }
	toast.configure({
		autoClose: 6000,
		draggable: true,
		hideProgressBar: false,
		position: toast.POSITION.BOTTOM_LEFT,
		icon: false,
	});

	const [isActivities, setIsActivities] = useState(true);
	const [crmType, setCrmType] = useState("leads");

	const onDrop = useCallback(
		(acceptedFiles, fileRejections) => {
			// console.log('fileRejection', fileRejections)
			console.log("acceptedFiles", acceptedFiles);

			if (!acceptedFiles.length) {
				toast.error(<Alert severity='error'>please upload a csv file</Alert>, {
					closeOnClick: true,
					autoClose: true,
					closeButton: true,
					newestOnTop: true,
				});
			}

			console.log("coming from basic.js ondrop", acceptedFiles);
			props.setSelectedFileData({ selectedFile: acceptedFiles[0] });
			props.setSelectFileName(acceptedFiles[0].name);

			//get dbMapping values from constant.js based on crmType
			const dbMappings = {
				leads: crmLeadsAllDbMappings,
				accounts: crmAccountsAllDbMappings,
				contacts: crmContactsAllDbMappings,
				opportunities: crmOpportunitiesAllDbMappings,
			};
			acceptedFiles.forEach((file) => {
				const reader = new FileReader();

				reader.onabort = () => console.log("file reading was aborted");
				reader.onerror = () => console.log("file reading has failed");

				//this is fired when reader.readAsText is completed
				reader.onload = () => {
					// Do whatever you want with the file contents
					const binaryStr = reader.result;
					// console.log( "coming from onDrop", binaryStr)

					let parsedStr = papa.parse(binaryStr);
					console.log("paresedStr", parsedStr?.data[0]);
					props.setIsPicked(true);
					props.setCSVHeaders(parsedStr?.data[0]);

					const headers = {};
					if (!isActivities) {
						//getting dbMapping values for crmType
						const DbMapping = dbMappings[crmType];
						parsedStr?.data[0].forEach((ele, idx) => {
							headers[ele] = DbMapping[idx] ? DbMapping[idx] : "";
						});
					} else {
						parsedStr?.data[0].forEach((ele, idx) => {
							headers[ele] = allDbMappings[idx] ? allDbMappings[idx] : "";
						});
					}

					props.setHeaderMappings(headers);
				};
				reader.readAsText(file);
				// console.log("value of reader", reader)
			});
		},
		[isActivities, crmType]
	);

	const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
		maxFiles: 1,
		onDrop,
		accept: {
			"text/csv": [".csv"],
		},
	});

	const files = acceptedFiles.map((file) => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
		</li>
	));

	const handleDownload = async (event) => {
		if (isActivities) {
			console.log("code coming inside handleDownload");
			console.log(`${window._env_.OFFLINE_UPLOAD}/offline-data-import/get-csv-template`);
			let response = await fetch(`${window._env_.OFFLINE_UPLOAD}/offline-data-import/get-csv-template`, {
				credentials: "include",
				method: "GET",
			});

			console.log("response from", response);
			if (response.ok) {
				const blob = await response.blob();
				console.log("response1", blob);
				const url = window.URL.createObjectURL(new Blob([blob]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("href", `${window._env_.OFFLINE_UPLOAD}/offline-data-import/get-csv-template`);
				document.body.appendChild(link);
				// window.location.assign(link);
				link.click();

				// let result = response.json()
			}
		} else {
			//get template files to download for crmType
			const crm_type = crmType;
			const _url = `${window._env_.OFFLINE_UPLOAD}/offline-data-import/get-crm-csv-template/${crm_type}`;
			let response = await fetch(_url, {
				credentials: "include",
				method: "GET",
			});

			if (response.ok) {
				const blob = await response.blob();
				const url = window.URL.createObjectURL(new Blob([blob]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("href", _url);
				document.body.appendChild(link);
				// window.location.assign(link);
				link.click();
			}
		}
	};

	const handleDropdownChange = (event) => {
		//set states on dropdown change
		if (event.target.value === "crm") {
			setIsActivities(false);
			props.setIsActivities(false);
		} else {
			setIsActivities(true);
			setCrmType("leads");
			props.setCrmType("leads");
			props.setIsActivities(true);
		}
	};
	const handleCRMChoice = (event) => {
		// console.log('code coming inside handleCRMChoice ' + event.target.value);
		setCrmType(event.target.value);
		props.setCrmType(event.target.value);
	};

	const theDropdown = (
		//The dropdown menu for choice between activities and crm
		<FormControl name='type_choice'>
			<InputLabel>Choose</InputLabel>
			<Select defaultValue='activities' label='Choose' onChange={handleDropdownChange}>
				<MenuItem value='activities'>Activities</MenuItem>
				<MenuItem value='crm'>CRM</MenuItem>
			</Select>
		</FormControl>
	);

	const crmDropdown = (
		//The dropdown menu for choice of crm. i.e, leads, accounts, contacts, opportunities
		<FormControl name='crm_type'>
			<InputLabel>CRM Type</InputLabel>
			<Select defaultValue='leads' label='CRM Type' onChange={handleCRMChoice}>
				<MenuItem value='accounts'>Accounts</MenuItem>
				<MenuItem value='contacts'>Contacts</MenuItem>
				<MenuItem value='opportunities'>Opportunities</MenuItem>
				<MenuItem value='leads'>Leads</MenuItem>
			</Select>
		</FormControl>
	);

	const activitiesUploadForm = (
		//upload form for activities
		<section className='dropzone_container'>
			<div {...getRootProps({ className: "dropzone" })}>
				<input {...getInputProps()} />
				<p style={{ fontWeight: "700", fontSize: "16px", color: "#184D27" }}>
					Drop Files here or <span style={{ textDecoration: "underline", cursor: "pointer" }}>Browse</span>
				</p>
				<p style={{ fontWeight: "500", fontSize: "16px", color: "#030303", opacity: "0.5" }}>Make sure the file is in csv format</p>
			</div>
			<p style={{ fontWeight: "500", fontSize: "14px", color: "#030303", opacity: "0.5" }}>
				you can{" "}
				<span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={handleDownload}>
					Download
				</span>{" "}
				a sample template for {isActivities ? 'Activities' : crmType}
			</p>
			{}
		</section>
	);

	const crmUploadForm = (
		//upload for for crm. essentially is crm dropdown + activities upload form but different calls and actions
		<div>
			{crmDropdown}
			{activitiesUploadForm}
		</div>
	);
	return (
		//display on screen
		<div>
			{theDropdown}
			{isActivities ? activitiesUploadForm : crmUploadForm}
		</div>
	);
}

export default DropZone;
