export const mandatoryMappingFields = [
	'Email Address',
	'Company Website',
	'Activity Date',
	'Activity Type',
	'Activity Level'
];
export const otherMappingFields = [
	'First Name',
	'Last Name',
	'Company Name',
	'Designation',
	'Mobile Number',
	'City',
	'Source',
	'Sub Source',
	'Product Name',
	'Event/Campaign Name',
	'Channel',
];
export const allDbMappings = [...mandatoryMappingFields, ...otherMappingFields];

//#region CRM Leads mapping fields
export const crmLeadsMandatoryMappingFields = ['First Name', 'Email'];
export const crmLeadsOtherMappingFields = [
	'Salutation',
	'Last Name',
	'Company',
	'Department',
	'Rating',
	'Designation',
	'Product Interest',
	'Lead Status',
	'Mobile',
	'Phone',
	'Lead Source',
	'Industry',
	'No of Employees',
	'Annual Revenue',
	'Country',
	'Street',
	'City',
	'Postal Code',
	'State',
	'Website',
	'Reason For Not Qualified',
	'Description',
	'Lead Owner',
];
export const crmLeadsAllDbMappings = [...crmLeadsMandatoryMappingFields, ...crmLeadsOtherMappingFields];
//#endregion

//#region CRM Accounts mapping fields
export const crmAccountsMandatoryMappingFields = ['Account Name'];
export const crmAccountsOtherMappingFields = [
	'Annual Revenue',
	'Region',
	'Country',
	'State',
	'City',
	'Industry',
	'Funding',
	'Fundiing Round',
	'Website',
	'Number of Employees',
	'Revenue Bucket',
	'Investors',
	'Last Funding Amount',
	'Jellyfish Accounts',
	'LinkedIn',
	'Facebook',
	'Twitter',
];
export const crmAccountsAllDbMappings = [...crmAccountsMandatoryMappingFields, ...crmAccountsOtherMappingFields];
//#endregion

//#region CRM Contacts mapping fields
export const crmContactsMandatoryMappingFields = ['Account Id', 'First Name'];
export const crmContactsOtherMappingFields = [
	'Last Name',
	'Email Address',
	'Department',
	'Title',
	'Person Level',
	'Stage',
	'Region',
	'Country',
	'State',
	'City',
	'LinkedIn'
];
export const crmContactsAllDbMappings = [...crmContactsMandatoryMappingFields, ...crmContactsOtherMappingFields];
//#endregion

//#region CRM Opportunities mapping fields
export const crmOpportunitiesMandatoryMappingFields = [
	'AccountId',
	'Name',
];
export const crmOpportunitiesOtherMappingFields = [
	'OpportunityOwner',
	'NextStep',
	'Amount',
	'Stage',
	'Probability',
	'CreatedDate',
	'LastModifiedDate',
	'CloseDate',
	'LeadSource',
	'OpportunityType',
	'WeightedAmount',
	'Age',
	'MRR',
	'ARR',
	'ProductARR',
	'ServicesARR',
	'TotalARR',
	'OpportunityStatus',
	'OpportunitySource',
	'WonReason',
	'LostReason',
	'SerivcesOffered',
	'Pipeline',
	'PipelineAmount',
	'Currency'
];
export const crmOpportunitiesAllDbMappings = [...crmOpportunitiesMandatoryMappingFields, ...crmOpportunitiesOtherMappingFields];
//#endregion
