import './App.css';
import { ThemeProvider } from '@material-ui/styles';
import UItheme from "@bamboobox/b2-theme";
import { AuthProvider } from "@bamboobox/b2logincheck";
import { HashRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import React from 'react';
import { version } from '../package.json';
// import { useClearCache } from 'react-clear-cache';
// import CacheBuster from 'react-cache-buster';
import ManualObjectStart from './ManualObjectStart';
import { useEffect } from 'react';

function App() {

  return (
      <React.Fragment>
              
    <div className="App">
        {/* <AuthProvider loginURL={window._env_.LOGIN_URL} authcheckURL={window._env_.AUTH_URL}> */}
            {/* <ThemeProvider theme={UItheme}> */}
      
                    <Routes>
                        <Route exact path="/" element={<ManualObjectStart/>}></Route>
                    </Routes>
    
                
            {/* </ThemeProvider> */}
        {/* </AuthProvider> */}
    </div>
    </React.Fragment>
);
}

export default App;
