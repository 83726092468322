import React, { useEffect, useState } from "react";
import { Button, Typography, IconButton } from "@material-ui/core";
import { Add, ContactSupportOutlined } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import "./MObjectStart.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./toast.css";
import Alert from "@mui/material/Alert";
import DropZone from "./components/DropZone";
import { useAuthState } from "@bamboobox/b2logincheck";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
	mandatoryMappingFields,
	otherMappingFields,
	crmLeadsMandatoryMappingFields,
	crmLeadsOtherMappingFields,
	crmAccountsMandatoryMappingFields,
	crmAccountsOtherMappingFields,
	crmContactsMandatoryMappingFields,
	crmContactsOtherMappingFields,
	crmOpportunitiesMandatoryMappingFields,
	crmOpportunitiesOtherMappingFields,
} from "./constants";
import DeleteIcon from "@material-ui/icons/Delete";
import {
	allDbMappings,
	crmLeadsAllDbMappings,
	crmAccountsAllDbMappings,
	crmContactsAllDbMappings,
	crmOpportunitiesAllDbMappings,
} from "./constants";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

function ManualObjectStart(props) {
	const classes = useStyles();

	toast.configure({
		autoClose: 6000,
		draggable: true,
		hideProgressBar: false,
		position: toast.POSITION.BOTTOM_LEFT,
		icon: false,
	});

	const [showDefault, setShowDefault] = useState(0);
	const [open, setOpen] = useState(false);
	const [selectedFileData, setSelectedFileData] = useState();
	const [isPicked, setIsPicked] = useState(false);
	const [selectFileName, setSelectFileName] = useState();
	const [csvheaders, setCSVHeaders] = useState([]);
	const tenantId = useAuthState().user.tenantId;
	const [columnsmatched, setColumnsMatched] = useState(allDbMappings.length);
	const [columnsleft, setColumnsLeft] = useState(0);
	const [headerMappings, setHeaderMappings] = useState({});
	const [preSelectedMappings, setPreSelectedMappings] = useState([...allDbMappings]);

	const [backDropOpen, setBackDropOpen] = useState(false);

	const [crmType, setCrmType] = useState("leads");
	const [isActivities, setIsActivities] = useState(true);
	const [mandatoryMappings, setMandatoryMappings] = useState([...mandatoryMappingFields]);
	const [otherMappings, setOtherMappings] = useState([...otherMappingFields]);
	const [allMappings, setAllMappings] = useState([...allDbMappings]);

	// const ref = React.createRef();

	const handleNewClick = (event) => {
        setBackDropOpen(false); // if file upload failed -> loader must be removed
		setOpen(true);
	};

	const handleCloseDialog = (event) => {
		setOpen(false);
		reSelect();
	};

	const selectChangeHandler = (event, ele1) => {
		console.log("event cominng from changeHandler", event?.target?.value);
		console.log("event cominng from changeHandler1", ele1);

		let newHeaderColumnsValue = {};
		newHeaderColumnsValue[ele1] = event.target.value;

		setHeaderMappings((prev) => {
			return { ...prev, ...newHeaderColumnsValue };
		});

		// console.log("headerMappings", headerMappings)

		// if (mandatoryMappingFields.includes(event?.target?.value) && columnsleft > 0) {
		//     let decrement = columnsleft - 1
		//     setColumnsLeft(decrement)
		// }

		// if (columnsmatched < csvheaders.length) {
		//     let increment = columnsmatched + 1
		//     setColumnsMatched(increment)
		// }
	};

	//all mapping fields for each crm type from constants.js
	const getMappingFields = (_crmType) => {
		const mappings = {
			leads: [crmLeadsMandatoryMappingFields, crmLeadsOtherMappingFields, crmLeadsAllDbMappings],
			accounts: [crmAccountsMandatoryMappingFields, crmAccountsOtherMappingFields, crmAccountsAllDbMappings],
			contacts: [crmContactsMandatoryMappingFields, crmContactsOtherMappingFields, crmContactsAllDbMappings],
			opportunities: [crmOpportunitiesMandatoryMappingFields, crmOpportunitiesOtherMappingFields, crmOpportunitiesAllDbMappings],
		};

		return {
			mandatoryMappingFields: mappings[_crmType][0],
			otherMappingFields: mappings[_crmType][1],
			allMappingFields: mappings[_crmType][2],
		};
	};

	const mapForActivities = (_headerMappings) => {
		// console.log('mapforActivities');
		let headerMappingAllValuesArr = [];
		let lengthofheaderMappings = 0;

		// console.log('going in for mapForActivities headerMappings');
		for (let key in _headerMappings) {
			if (_headerMappings[key]) {
				lengthofheaderMappings += 1;
				headerMappingAllValuesArr.push(_headerMappings[key]);
				// console.log(`headerMapppings[${key}]: ${_headerMappings[key]}`);
			}
		}

		let mandatoryMatched = 0;
		let uniqueheaderMappingValuesArr = new Set(headerMappingAllValuesArr);
		for (let mapping_ele of mandatoryMappingFields) {
			uniqueheaderMappingValuesArr.forEach((header_ele) => {
				if (header_ele === mapping_ele) {
					mandatoryMatched += 1;
				}
			});
		}

		setColumnsLeft(mandatoryMappingFields.length - mandatoryMatched);
		setColumnsMatched(lengthofheaderMappings);

		setMandatoryMappings([...mandatoryMappingFields]);
		setOtherMappings([...otherMappingFields]);
		setAllMappings([...allDbMappings]);

		// console.log('mandatoryMatched', mandatoryMatched);
		// console.log('mandatoryMappingFields', mandatoryMappingFields);
		// console.log('columnsLeft ', columnsleft);
		// console.log('columnsMactched ', columnsmatched);
	};

	const mapForCRM = (_headerMappings, _crmType) => {
		// console.log('mapforCRM');
		let headerMappingAllValuesArr = [];
		let lengthofheaderMappings = 0;

		// console.log('going in for mapforCRM headerMappings');
		for (let key in _headerMappings) {
			if (_headerMappings[key]) {
				lengthofheaderMappings += 1;
				headerMappingAllValuesArr.push(_headerMappings[key]);
				// console.log(`headerMapppings[${key}]: ${_headerMappings[key]}`);
			}
		}

		let mandatoryMatched = 0;
		let uniqueheaderMappingValuesArr = new Set(headerMappingAllValuesArr);
		const { mandatoryMappingFields, otherMappingFields, allMappingFields } = getMappingFields(_crmType);
		// console.log('mandatoryMappingFields', mandatoryMappingFields);

		for (let mapping_ele of mandatoryMappingFields) {
			uniqueheaderMappingValuesArr.forEach((header_ele) => {
				if (header_ele === mapping_ele) {
					mandatoryMatched += 1;
				}
			});
		}

		setColumnsLeft(mandatoryMappingFields.length - mandatoryMatched);
		setColumnsMatched(lengthofheaderMappings);

		setMandatoryMappings([...mandatoryMappingFields]);
		setOtherMappings([...otherMappingFields]);
		setAllMappings([...allMappingFields]);
	};

	useEffect(() => {
		//call mapping method based on whether activites if chosen or not
		isActivities ? mapForActivities(headerMappings) : mapForCRM(headerMappings, crmType);
	}, [crmType, headerMappings, isActivities]);

	const reSelect = () => {
		//reset all values/states to default
		setIsPicked(false);
		setHeaderMappings([]);
		setCSVHeaders([]);
		setSelectFileName(undefined);
		setSelectedFileData(undefined);
		setHeaderMappings({});
		setColumnsLeft(0);
		setIsActivities(true);
		setCrmType("leads");
	};

	const toastMessageCRM = (isSuccess, _message) => {
		!isSuccess
			? toast.error(<Alert severity='error'>{_message}</Alert>, {
					closeOnClick: true,
					autoClose: true,
					closeButton: true,
					newestOnTop: true,
			  })
			: toast.success(<Alert severity='success'>{_message}</Alert>, {
					closeOnClick: true,
					autoClose: true,
					closeButton: true,
					newestOnTop: true,
			  });
	};

	const uploadCRM = async (data) => {
		//check for mandatory fields unmatched. if unmatched, show error toast message
		if (columnsleft > 0) {
			toast.error(<Alert severity='error'>{columnsleft} mandatory columns left</Alert>, {
				closeOnClick: true,
				autoClose: true,
				closeButton: true,
				newestOnTop: true,
			});
			setBackDropOpen(false);
			return;
		}

		//call to api to upload the file based on crm type
		const url = `${window._env_.OFFLINE_UPLOAD}/offline-data-import/post-crm/${crmType}/${tenantId}`;
		const response = await fetch(url, {
			credentials: "include",
			method: "POST",
			body: data,
		});
		const result = await response.json();
		setOpen(false);
		reSelect();

		//show relevant toast messages
		if (result.success) {
			setBackDropOpen(false);
			toastMessageCRM(true, result.message);
		} else {
			toastMessageCRM(false, result.message);
		}
	};

	const uploadButtonClicked = async (event) => {
		setBackDropOpen(true);

		var data = new FormData();
		data.append("file", selectedFileData?.selectedFile);
		data.append("fileName", selectFileName);
		data.append("headerMappings", JSON.stringify(headerMappings));
		data.append("order", JSON.stringify(csvheaders));

		//upload crm data if not activity
		if (!isActivities) {
			uploadCRM(data);
			return;
		}

		if (columnsleft > 0) {
			toast.error(<Alert severity='error'>{columnsleft} mandatory columns left</Alert>, {
				closeOnClick: true,
				autoClose: true,
				closeButton: true,
				newestOnTop: true,
			});

			return;
		}

		console.log('requestOptions from manualupload', data);
        console.log('tenantId :', tenantId)

		const res = await fetch(`${window._env_.OFFLINE_UPLOAD}/offline-data-import/upload-csv-data/${tenantId}`, {
        // const res = await fetch(`test.bamboobox.in:3000/offline-data-import/upload-csv-data/${tenantId}`, {
			credentials: 'include',
			method: 'POST',
			body: data,
		});

		const result = await res.json();

		console.log("result from fileupload", result);

		setOpen(false);
		reSelect();

		if (result.success) {
			setBackDropOpen(false);

			toast.success(<Alert severity='success'>{result.message}</Alert>, {
				closeOnClick: true,
				autoClose: true,
				closeButton: true,
				newestOnTop: true,
			});
		} else {
			toast.error(<Alert severity='error'>{result.message}</Alert>, {
				closeOnClick: true,
				autoClose: true,
				closeButton: true,
				newestOnTop: true,
			});
		}
	};

	return (
		<React.Fragment>
			{
				<Dialog open={open} name='dialog_box' onClose={handleCloseDialog} aria-labelledby='form-dialog-title'>
					{/* <DialogTitle id="form-dialog-title"></DialogTitle> */}
					<div className='mainColumnContainer'>
						{!isPicked && (
							<DropZone
								setSelectFileName={setSelectFileName}
								setSelectedFileData={setSelectedFileData}
								setIsPicked={setIsPicked}
								setCSVHeaders={setCSVHeaders}
								setHeaderMappings={setHeaderMappings}
								setCrmType={setCrmType}
								setIsActivities={setIsActivities}
							/>
						)}
						{isPicked && (
							<div>
								<div className='filenamebox'>
									<div className='filename'>{selectFileName}</div>
									<div style={{ color: "#03030399", cursor: "pointer" }} onClick={reSelect}>
										<DeleteIcon></DeleteIcon>
									</div>
								</div>
								<hr></hr>
								<div className='columnsfields'>
									<div>CSV COLUMNS</div>
									<div>BAMBOOBOX FIELDS</div>
								</div>
								<div className='actualColumns'>
									{csvheaders.map((ele1, idx) => {
										// console.log("idx coming from map", idx)
										return (
											<div className='mappingSelection' key={idx}>
												<label className='labels'>{ele1}</label>
												<FormControl variant='outlined'>
													{/* <InputLabel htmlFor="grouped-select">label</InputLabel> */}
													<Select
														onChange={(event) => selectChangeHandler(event, ele1)}
														displayEmpty
														renderValue={(selected) => {
															// console.log('log from renderValue', selected);
															if (selected?.length === 0) {
																return <p>{allMappings[idx]}</p>;
															}

															return selected;
														}}
														defaultValue=''
														id='grouped-select'
													>
														<ListSubheader>MANDATORY</ListSubheader>
														{mandatoryMappings.map((ele2, index) => {
															return (
																<MenuItem key={ele2} value={ele2}>
																	{ele2}
																</MenuItem>
															);
														})}

														<ListSubheader>OTHERS</ListSubheader>
														{otherMappings.map((ele3, index) => {
															return (
																<MenuItem key={ele3} value={ele3}>
																	{ele3}
																</MenuItem>
															);
														})}
													</Select>
												</FormControl>
											</div>
										);
									})}
								</div>
							</div>
						)}
						<div className='syncbutton'>
							{!isPicked && <p className='parasubtext'>select file to continue with mapping</p>}
							{isPicked && (
								<p className='parasubtext'>
									Matched {columnsmatched} of {csvheaders.length} columns {columnsleft} mandatory columns left
								</p>
							)}
							<Button
								title='Upload the selected file'
								variant='contained'
								style={{
									backgroundColor: isPicked ? "#21DA8C" : "#e0e0e0",
									color: "white",
									borderRadius: "18px",
									height: "32px",
									width: "86px",
									fontSize: "12px",
									fontWeight: 800,
									letterSpacing: "0.08em",
								}}
								onClick={() => {
									uploadButtonClicked();
								}}
								className='btn'
								disabled={isPicked ? false : true}
							>
								Sync
							</Button>
							<Backdrop
								sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
								open={backDropOpen}
								// onClick={handleClose}
							>
								<CircularProgress color='inherit' />
							</Backdrop>
						</div>
					</div>
					<DialogActions>
						{/* <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleClose} color="primary">
                    Subscribe
                </Button> */}
					</DialogActions>
				</Dialog>
			}
			<div
				className='ExportHead'
				style={{
					display: "flex",
					paddingTop: "16px",
					flexDirection: "column",

					height: "80%",
				}}
			>
				<div style={{ marginRight: "32px", display: "flex", justifyContent: "space-between" }}>
					<div>
						<Typography
							style={{
								display: "flex",
								justifyContent: "left",
								paddingLeft: "32px",
								color: "#595959",
								fontWeight: 700,
								fontSize: "20px",
								lineHeight: "100%",
								align: "left",
								verticalAlign: "top",
							}}
						>
							Data Upload
						</Typography>
					</div>
				</div>

				<div 
					// style={{ 
					// 	display: "flex" 
					// }}
				>
					{/* <div
						style={{
							background: "#FFFFFF",
							boxShadow:
								"0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)",
							boxSizing: "border-box",
							borderRadius: "0px 16px 16px 0px",
							padding: "30px 24px",
							marginTop: "24px",
							width: "300px",
							height: "calc(100vh - 70px)",
							flexShrink: "0",
							position: "sticky",
						}}
					> */}
						{/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
							{/* <Typography
								style={{
									fontFamily: "Nunito Sans !important",
									fontWeight: 700,
									fontSize: "13px",
									lineHeight: "100%",
									color: "#595959",
									paddingTop: "8px",
									letterSpacing: "6%",
								}}
							>
								OBJECT LIST
							</Typography> */}

							{/* <Button
								color='#21DA8C'
								variant='contained'
								style={{
									width: "77px",
									height: "32px",
									fontSize: "14px",
									fontWeight: 700,
									textTransform: "none",
									backgroundColor: "#21DA8C",
									color: "#FFFFFF",
									borderRadius: "18px",
								}}
								onClick={() => {}}
								startIcon={<Add color='default' />}
							>
								DATA
							</Button> */}
							{/* <Divider orientation="vertical"/> */}
						{/* </div> */}
						{/* <ExportLogListing setShowDefault={setShowDefault} showNew={showNew} showEdit={showEdit} setShowNew={setShowNew} setShowEdit={setShowEdit} setShowPreview={setShowPreview} 
            rowsChanged={rowsChanged} setRowsChanged={setRowsChanged} 
             filterBody={filterBody} setFilterBody={setFilterBody} filType={filType} setFilType={setFilType}
        snackbar={exportInitiated} filterName={filterName} setFilterName={setFilterName} expType={expType} setExpType={setExpType} icp={icp}
        saveSegment={ saveSegment} setSaveSegment = {setSaveSegment}
        ></ExportLogListing> */}
					{/* </div> */}

					{showDefault === 0 && (
						<div
							style={{
								// paddingTop: "35px",
								// paddingLeft: "32px",
								position: "relative",
								height: "calc(100vh-100px) !important",
								// width: "80vw",
								width: "100%",
							}}
						>
							<div
								style={{
									// margin: 0,
									// position: "absolute",
									margin: "auto",
									position: "fixed",
									top: "50%",
									left: "37%",
									transform: "translateY(-50%)",
									textAlign: "center",
								}}
							>
								<p style={{ color: "#CACACA", fontWeight: 600, fontSize: "18px", lineHeight: "18px" }}>
									Add offline Activities or CRM data in a Bulk as a CSV file
								</p>
								<Button
									color='#21DA8C'
									variant='contained'
									style={{
										width: "108px",
										height: "32px",
										fontSize: "14px",
										fontWeight: 700,
										textTransform: "none",
										backgroundColor: "#21DA8C",
										paddingTop: "8px",
										color: "#FFFFFF",
										borderRadius: "18px",
										boxShadow:
											"0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
									}}
									onClick={() => {
										handleNewClick();
									}}
									startIcon={<Add color='default' />}
								>
									DATA
								</Button>
							</div>
						</div>
					)}
				</div>
			</div>
		</React.Fragment>
	);
}

export default ManualObjectStart;
